import React, { useEffect, useState } from 'react'

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Search from '../../components/SearchContainer';
import JobList from '../../components/JobList/JobList';
import './../../sass/main.scss'
import logoRona from '../../images/logo-rona.png'
import logoRenoDepot from '../../images/logo-renodepot-en.png';

import banners from '../../images/LowesCan_Bande-de-logo-LW-RO-RD-VER-court-FR_RENV.svg'

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import playButton from '../../images/play-button.png'
import closeButton from '../../images/close-button.svg';
import maudeBVideo from '../../images/maude-b.mp4'
import maudeBVideoFR from '../../images/Maude_Bérubé_V1_sous_titres_FR.mp4'
import bryanDVideoEN from '../../images/Bryan_Dutot_sans_sous_titres_v2.mp4'
import bryanDVideoFR from '../../images/Bryan_Dutot_sans_sous_titres_v2.mp4'
import lilianeNVideoFR from '../../images/liliane-n.mp4'
import lilianeNVideoEN from '../../images/Liliane_Nguyen_sans_sous_titres_v1_pas_de_top_employer_ANG.mp4'
import sampleVideo2 from '../../images/sample2.mp4'
import maudeB from '../../images/maude-b-headshot.png'
import bryanD from '../../images/bryan-d-headshot.png'
import lilianeN from '../../images/liliane-n-headshot.jpeg'

const testimonialsEN = [{
    name: 'Bryan Dutot', location: 'Leamington, Ontario', video: bryanDVideoEN, headshot: bryanD
}]
const testimonialsFR = [{
    name: 'Liliane Nguyen',
    location: 'Montréal, Québec',
    locationFR: 'Montréal, Québec',
    video: lilianeNVideoFR,
    headshot: lilianeN
}, {
    name: 'Maude Bérubé',
    location: 'Montreal, Quebec',
    locationFR: 'Montréal, Québec',
    video: maudeBVideoFR,
    headshot: maudeB
}]

const testimonials = process.env.LANG === 'FR' ? testimonialsFR : testimonialsEN;

let EN_URL = process.env.EN_URL ? process.env.EN_URL : '/';
let FR_URL = process.env.FR_URL ? process.env.FR_URL : '/';
SwiperCore.use( [Autoplay, Pagination, Navigation] );

const SearchPage = () => {
    let isFrench = process.env.LANG === 'FR';
    let isInternal = process.env.INT_EXT === 'internal';
    const [videoURL, setVideoURL] = useState();
    const [showLightBox, setShowLightBox] = useState( false );
    const closeLightBox = () => {
        document.getElementById( 'lightbox_video' ).pause();
        setShowLightBox( false );
    }
    const openLightBox = ( e ) => {
        setShowLightBox( true );
        alert( e.target.getAttribute( 'video' ) );
        setVideoURL( e.target.getAttribute( 'video' ) );
        // document.getElementById( 'lightbox_video' ).play();
    }
    useEffect( () => {
        if(isFrench){
            let url = new URL( window.location.href );
            const term = url.searchParams.get( 'term' );
            const type = url.searchParams.get( 'type' );
            window.location.href = `/recherche/?term=${term ? term : ''}&type=${type ? type : ''}`
        }
    } );
    return (<>
        <Header title={'Search all Available Jobs | RONA inc., Rona, and Reno Depot'}
                description={'Search all available job opportunities with RONA inc., Rona, and Réno Dépôt here.'}
                breadcrumbs={[{
                    text: process.env.LANG === 'FR' ? `Recherche` : `Search`,
                    url: process.env.LANG === 'FR' ? `/recherche` : `/search`
                }]}
                // redirect={isFrench ? `/recherche/?term=${term ? term : ''}&type=${type ? type : ''}` : false}
                lang={process.env.LANG ? process.env.LANG : 'en'}
                lang_link={process.env.LANG === 'FR' ? `${EN_URL}/search` : `${FR_URL}/recherche`}
                bodyClasses={'page-search'}/>
        <main id={'main'}>
            <section className={'pt4 container-xxl'}>
                <a href="/">
                    <button
                        className={'button'}>{process.env.LANG === 'FR' ? `Retourner à la carte` : `Back to Map`}</button>
                </a>
            </section>
            {isInternal ? <section style={{padding: 0}} className={'internal-carousel c mt5 container-xxl'}>
                <div className={showLightBox ? 'lightbox c jc ac' : 'lightbox c jc ac dn'}>
                    <div className={'video-content c jc afe '}>
                        <a className={'tar c jac ac'} onClick={closeLightBox}>
                            <img src={closeButton} alt={'Close Button'}/>
                        </a>
                        <video src={videoURL} id={'lightbox_video'} autoPlay controls/>
                    </div>
                </div>
                <div className={'r jc ac'}>
                    <div className={'w70'}>
                        <div className={'r jc ac d-n-xxl lowes-bg-img'}>
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                // loop={true}
                                // autoplay={{'delay': 5000}}
                                pagination={{clickable: true}}
                                style={{height: '100%', width: '100%'}}
                                className={'swiper1'}
                            >
                                <SwiperSlide>
                                    <div className={`${isFrench ? 'slider-image-2-fr' : 'slider-image-2'} r ac`}/>
                                    <a href="https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021485"
                                       target={'blank'}
                                       className={'r ac jfs bottom-banner'}>
                                        <h3 style={isFrench ? {fontSize: 16} : {}}
                                            className={isFrench ? 'ml5 pl2' : 'ml6 pl4'}>{isFrench ? 'Cliquez ici pour référer une connaissance sur n’importe quel poste disponible chez RONA inc.!' : 'Click here to refer a candidate to any available positions at RONA inc.!'}</h3>
                                        <a href={isFrench ? 'https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021486' : 'https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021485'}
                                           target={'blank'} className={'ml3'}>
                                            <button className={'button'}>{isFrench ? 'Référer' : 'Refer'}</button>
                                        </a>
                                    </a>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className={'w30 w100-xxl'} style={{background: 'linear-gradient(to bottom, #12AAE2 37%, #0075C9 37%)'}}>
                        <div className={'tac testimonials-container '} style={{zIndex: 300}}>
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                className={'swiper2'}
                                loop={false}
                                autoplay={{'delay': 10000}}
                                pagination={{clickable: true}}
                                style={{height: '100%', width: '100%', padding: '3.5rem 2rem 3.5rem 2rem'}}
                            >
                                {testimonials.map( ( t ) => {
                                    return (<SwiperSlide className={'c jc ac'}>
                                        <span className={'cw'} style={{fontSize: 30}}>Testimonials</span>
                                        <img className={'mt3 mb2 headshot'} width="150" alt={`${t.name} Headshot`}
                                             src={t.headshot}/>
                                        <div className={'play-button'} onClick={() => {
                                            setShowLightBox( true );
                                            setVideoURL( t.video );
                                        }}>
                                            <svg video={t.video} version="1.1" className="play-button"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 x="0px" y="0px" height={'40'} width={'40'}
                                                 viewBox="0 0 60 60"
                                            >
                                                <g>
                                                    <path className={'color-fill'} fill="#ffffff"
                                                          d="M45.6,29.2l-22-15c-0.3-0.2-0.7-0.2-1-0.1C22.2,14.3,22,14.6,22,15v30c0,0.4,0.2,0.7,0.5,0.9C22.7,46,22.8,46,23,46 c0.2,0,0.4-0.1,0.6-0.2l22-15c0.3-0.2,0.4-0.5,0.4-0.8S45.8,29.4,45.6,29.2z M24,43.1V16.9L43.2,30L24,43.1z"/>
                                                    <path className={'color-fill'} fill="#ffffff"
                                                          d="M30,0C13.5,0,0,13.5,0,30s13.5,30,30,30s30-13.5,30-30S46.5,0,30,0z M30,58C14.6,58,2,45.4,2,30S14.6,2,30,2s28,12.6,28,28 S45.4,58,30,58z"/>
                                                </g>
                                                <polygon className={'color-fill'} fill="#ffffff"
                                                         points="45.9,29.8 22.9,15.5 22.9,44.2 "/>
                                            </svg>

                                        </div>
                                        <p style={{
                                            color: '#ffffff', fontSize: 30, marginTop: 15, marginBottom: 8
                                        }}>{t.name}</p>
                                        <p style={{
                                            color: '#ffffff', marginBottom: 8
                                        }}>{t.location}</p>
                                    </SwiperSlide>)
                                } )}
                            </Swiper>
                        </div>
                    </div>


                </div>
            </section> : <section style={{padding: 0}} className={'c container-xxl'}>
                <div className={'c px3'}>
                    <div className={'c w100'}>
                        {process.env.LANG === 'FR' ?
                            <p>Pour soumettre votre candidature, cliquez sur le <b>titre</b> du poste qui vous
                                intéresse, puis sur le bouton <b>Postuler</b>. Si ce n’est pas déjà fait, vous
                                aurez
                                à vous créer un <b>profil</b> afin de poursuivre le processus.</p> : <>
                                <p>To apply, click on the job posting title, then click the Apply button. If you
                                    do
                                    not
                                    already have a candidate profile, you will need to create one to complete
                                    the
                                    process.</p>
                                <p>If the positions currently available do not correspond to your criteria, sign
                                    up
                                    for our
                                    Job Alerts to be informed of new job opportunities that fit your
                                    profile.</p></>}
                        {process.env.LANG === 'FR' ?
                            <p>Pour connaître les offres d’emploi chez <b>Dick’s Lumber</b>, consultez leur <a
                                href="http://www.dickslumber.com/about-dicks/careers/" target="_blank">site
                                Web</a> (anglais seulement).</p> :
                            <p>For job opportunities at <b>Dick’s Lumber</b>, visit their <a
                                href={'http://www.dickslumber.com/about-dicks/careers/'}
                                target={'_blank'}>Website.</a>
                            </p>}
                        <p className={'mb5'}>
                            <b>{process.env.LANG === 'FR' ? 'Au plaisir de vous accueillir dans notre grande famille!' : 'We look forward to welcoming you to the family!'}</b>
                        </p>
                        <div className={'r ac jc-md fww mt4'}>
                            <img className={'px3 my3 pt3'} style={{height: 40}} src={logoRona}
                                 alt={'Rona Logo'}/>
                            <img className={'px3 my3'} style={{height: 50}} src={logoRenoDepot}
                                 alt={'Reno Depot Logo'}/>
                        </div>
                    </div>
                </div>
            </section>}
            <section style={{padding: 0}} className={'c container-xxl'}>
                <JobList/>
            </section>
        </main>
        <Footer lang={process.env.LANG ? process.env.LANG : 'en'}/>
    </>)
}

export default SearchPage;
